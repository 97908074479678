import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { injectGlobal, css, keyframes } from 'emotion';
import { normalize } from 'polished';

import backgroundImage from './brian.jpg';
import coins from './coins.jpg';

const wiggle = keyframes`
    0% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(-10deg);
    }
`;

function useRainbow() {
    const [hue, setHue] = useState(220);

    useEffect(() => {
        function tick() {
            setHue(hue => (hue + 0.5) % 360);
            requestAnimationFrame(tick);
        }

        const id = requestAnimationFrame(tick);

        return () => {
            cancelAnimationFrame(id);
        };
    }, []);

    return `hsl(${hue}, 100%, 50%)`;
}

function App() {
    const color = useRainbow();

    const [showFirstImage, setShowFirstImage] = useState(false);

    return (
        <>
            <div
                className={css`
                    position: relative;
                    filter: blur(30px);
                    z-index: -1;
                `}
            >
                <div
                    className={css`
                        background-image: url(${backgroundImage});
                        background-position: center;
                        position: fixed;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100vw;
                        height: 100vh;
                    `}
                />
            </div>
            <div
                className={css`
                    max-width: 1000px;
                    min-height: 100vh;
                    margin-left: auto;
                    margin-right: auto;
                    background-image: url(${backgroundImage});
                    background-size: cover;
                    background-position: center;
                    padding-top: 10vh;
                    text-align: center;
                `}
            >
                <h1
                    style={{ color }}
                    className={css`
                        margin: 0;
                        padding: 0;
                        font-size: 8vmin;
                        text-shadow: 0px 0px 10px black;

                        transform-origin: center center;
                        animation: ${wiggle} 2s linear 0s infinite;
                        animation-fill-mode: both;
                    `}
                >
                    Congratulations!
                </h1>
                <p
                    className={css`
                        font-size: 3vmin;
                        padding-top: 5vh;
                        text-shadow: 0px 0px 10px black;
                        color: white;
                    `}
                >
                    Click{' '}
                    <a
                        style={{ color }}
                        rel="noopener noreferrer"
                        target="_blank"
                        href="http://bit.ly/2JNVhjJ"
                        onClick={() => {
                            setTimeout(() => {
                                setShowFirstImage(true);
                            }, 250);
                        }}
                    >
                        here
                    </a>{' '}
                    to claim your reward!
                </p>
                {showFirstImage && (
                    <>
                        <p
                            className={css`
                                font-size: 3vmin;
                                padding-top: 5vh;
                                text-shadow: 0px 0px 10px black;
                                color: white;
                            `}
                        >
                            Look Here!
                        </p>
                        <img
                            src={coins}
                            alt="Coins"
                            className={css`
                                width: 100%;
                            `}
                        />
                    </>
                )}
            </div>
        </>
    );
}

injectGlobal`
    ${normalize()}

    html {
        box-sizing: border-box;
    }

    * {
        box-sizing: inherit;
    }
`;

ReactDOM.render(<App />, document.getElementById('root'));
